<template>
    <BT-Blade-Item
        bladeName="subscription-invoice"
        :bladesData="bladesData"
        :canDelete="false"
        :canEdit="false"
        navigation="subscription-invoices"
        title="Subscription Invoice">
        <template v-slot="{ item }">
            <v-row no-gutters>
                <v-col cols="12" class="py-5 my-5">
                    <v-img :src="$vuetify.theme.dark ? '/img/logo-dark.png' : '/img/logo-light.png'" class="mx-auto" max-height="150px" max-width="50%">
                        <template v-slot:placeholder>
                            <v-icon color="primary" >mdi-account</v-icon>
                        </template>
                    </v-img>
                </v-col>
                <v-col cols="6" sm="4">
                    <BT-Field-String
                        v-model="item.invoiceNumber"
                        label="Invoice #" />
                </v-col>
                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        v-model="item.issuedOn"
                        label="Issued On" />
                </v-col>
                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        v-model="item.dueOn"
                        label="Due On" />
                </v-col>
                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        v-if="item.isPaid"
                        v-model="item.paidOn"
                        label="Paid On" />
                    <v-list-item v-else>
                        <v-list-item-content>
                            <v-list-item-title class="error--text">*Unpaid*</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12">
                    <v-divider class="my-1" />
                    <v-subheader>Line Items</v-subheader>
                    <BT-Table
                        :canRefresh="false"
                        :canSelect="false"
                        :headers="[
                            { text: 'QTY', value: 'quantity', textFilter: 'toDisplayNumber' },
                            { text: 'Description', value: 'description' },
                            { text: 'Unit Price', value: 'unitPrice', textFilter: 'toCurrency' },
                            { text: 'Total', value: 'total', display: true }]"
                        hideActions
                        :items="item.lineItems">
                        <template v-slot:listItem="{ item }">
                            <v-list-item-avatar>
                                <v-img :src="productLogoURL(item.itemID)" class="my-auto">
                                    <template v-slot:placeholder><v-icon class="primary--text">mdi-cube-outline</v-icon></template>
                                </v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.description }}</v-list-item-title>
                                <v-list-item-subtitle>
                                    <span>{{ item.quantity | toDisplayNumber }} </span>
                                    <span v-if="item.onOrder != item.quantity" class="error--text text-decoration-line-through">  ({{ item.onOrder | toDisplayNumber }})</span>
                                    <span class="ml-2">@{{ item.unitPrice | toCurrency }}ea</span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="item.unitPrice > 0 || item.quantity > 0">
                                {{ (item.unitPrice * item.quantity) | toCurrency }}
                            </v-list-item-action>
                        </template>
                        <template v-slot:total="{ item }">
                            <span>{{ (item.quantity * item.unitPrice) | toCurrency }}</span>
                        </template>
                    </BT-Table>
                </v-col>
                <v-col cols="12">
                    <v-row no-gutters>
                        <v-spacer />
                        <div>
                            <h4 class="text-right ma-3">Subtotal: {{ item.subTotal | toCurrency }}</h4>
                            <h4 class="text-right mx-3 mb-1">GST: {{ item.taxTotal | toCurrency }}</h4>
                        </div>
                    </v-row>
                    
                    <v-divider />

                    <v-row no-gutters>
                        <v-spacer />
                        <div>
                            <h3 class="text-right ma-3">Total: {{ item.amountTotal | toCurrency }}</h3>
                            <h2 v-if="!item.isPaid && item.amountPaid > 0" class="text-right error--text ma-5">Amount Paid: {{ item.amountPaid | toCurrency }}</h2>
                            <h2 v-if="!item.isPaid" class="text-right warning--text ma-5">
                                Amount Due: {{ item.amountTotal - item.amountPaid | toCurrency }}
                            </h2>
                        </div>
                    </v-row>
                </v-col>
            </v-row>
            
            <v-row no-gutters>
                <v-spacer />
                <BT-Print
                    buttonClass="mx-1"
                    :getFileName="item => `Invoice #${item.invoiceNumber}.pdf`"
                    :item="item"
                    :itemID="item.id"
                    small>
                    <template v-slot="{ item, strategy }">
                        <v-container v-if="item != null" class="pdf-page">
                            <v-row>
                                <v-col cols="8">
                                    <div style="height: 25px;" />
                                    <v-img v-if="strategy != 'jspdf'" class="pdf-logo" src="/img/logo-light.png" max-width="300px" max-height="300px" />
                                    <v-list-item three-line>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>BILLED BY</v-list-item-subtitle>
                                            <v-list-item-title><v-icon left small>mdi-account</v-icon><strong>BlitzIt Tech</strong></v-list-item-title>
                                            <v-list-item-subtitle><v-icon left small>mdi-email</v-icon>billing@blitzitweb.com.au</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="mt-3">
                                        <v-list-item-content>
                                            <v-list-item-subtitle><v-icon left small>mdi-arrow-right-bottom</v-icon>BILLED TO</v-list-item-subtitle>
                                            <v-list-item-title><v-icon left small>mdi-account</v-icon><strong>{{ item.buyer.companyName }}</strong></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h2>Invoice</h2>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Invoice #</v-list-item-subtitle>
                                            <v-list-item-title>{{ item.invoiceNumber }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Issued On</v-list-item-subtitle>
                                            <v-list-item-title>{{ item.issuedOn | toShortDate }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Due On</v-list-item-subtitle>
                                            <v-list-item-title>{{ item.dueOn | toShortDate }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                            
                            <v-row class="my-3" dense style="border-bottom: 1px solid lightgray;">
                                <v-col cols="2">QTY</v-col>
                                <v-col cols="6">DESCRIPTION</v-col>
                                <v-col cols="2">UNIT PRICE</v-col>
                                <v-col cols="2" style="text-align: right;">TOTAL</v-col>
                            </v-row>

                            <v-row dense v-for="(lineItem, index) in item.lineItems" :key="index"
                                style="font-size: 13px;">
                                <v-col cols="2">{{ lineItem.quantity | toDisplayNumber }}</v-col>
                                <v-col cols="6">{{ lineItem.description }}</v-col>
                                <v-col cols="2">{{ lineItem.unitPrice | toCurrency }}</v-col>
                                <v-col cols="2" style="text-align: right;">{{ lineItem.quantity * lineItem.unitPrice | toCurrency }}</v-col>
                            </v-row>
                            
                            <v-divider style="margin: 10px 0px;" />

                            <v-row dense style="font-size: 14px;">
                                <v-col cols="9" style="text-align: right;">Subtotal:</v-col>
                                <v-col cols="3" style="text-align: right;">{{ item.subTotal | toCurrency }}</v-col>
                            </v-row>
                            <v-row dense style="font-size: 14px;">
                                <v-col cols="9" style="text-align: right;">GST:</v-col>
                                <v-col cols="3" style="text-align: right;">{{ item.taxTotal | toCurrency }}</v-col>
                            </v-row>
                            
                            <v-divider style="margin: 10px 0px;" />
                            
                            <v-row>
                                <v-col cols="9" style="text-align: right;">Total:</v-col>
                                <v-col cols="3" style="text-align: right;">
                                    <h4>{{ item.amountTotal | toCurrency }}</h4>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                </BT-Print>
            </v-row>
            <BT-Snack v-model="msg" />
        </template>
    </BT-Blade-Item>
</template>

<script>
import { generateInvoicePDF} from '~pdfhelpers';

export default {
    name: 'Subscription-Invoice-Blade',
    components: {
        BTPrint: () => import('~components/BT-Print.vue'),
        BTTable: () => import('~components/BT-Table.vue')
        //BTSingleActionDialog: () => import('~components/BT-Single-Action-Dialog.vue')
    },
    data: function() {
        return {
            amountPaid: { v: 0 },
            msg: null,
            panelV: 0,
            isStripeLoading: false,
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        async printInvoice(invoice) {
            generateInvoicePDF(invoice, this.$BlitzIt);
        },
        paid(item) {
            item.amountPaid = this.amountPaid.v;
        },
        prepItem(item) {
            item.lineItems = item.lineItems.orderBy('sortNumber');
        }
    }
}
</script>