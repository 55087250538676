import { jsPDF } from 'jspdf';
import { toDisplayNumber, toCurrency } from '~helpers';

export async function generateInvoicePDF(invoice, BlitzIt) {
    var doc = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4'
    });

    var blueColor = '#005485';
    var lightBlackColor = '#3C3C3C';
    var leftM = 15;
    var rightM = 190;
    var currentY = 10;

    doc.setFillColor(blueColor);
    doc.rect(leftM, currentY, rightM - leftM, 7, 'F');
    currentY += 13;

    doc.setFont('Roboto', null, null);
    doc.setFontSize(18);

    //header
    doc.text(invoice.seller.companyName, leftM, currentY, null);
    doc.text('Invoice', rightM, currentY, { align: 'right' });

    //right column details
    doc.setFontSize(11);
    doc.setTextColor(lightBlackColor);

    //seller details
    var strList = [];
    if (BlitzIt.auth.session.company != null && BlitzIt.auth.session.company.primaryEmail != null) {
        strList.push('Email: ' + BlitzIt.auth.session.company.primaryEmail);
    }
    if (BlitzIt.auth.session.company != null && BlitzIt.auth.session.company.phoneNumber != null) {
        strList.push('Phone: ' + BlitzIt.auth.session.company.phoneNumber);
    }
    if (strList.length > 0) {
        var otherY = currentY + 6;
        doc.text(strList, leftM, otherY, { lineHeightFactor: 1.5 });
    }

    strList = [
        `Invoice #${invoice.invoiceNumber}`,
        `Customer Order #${invoice.customerOrderNumber}`,
        `Purchase Order #${invoice.purchaseOrderNumber}`,
        `Issued On: ${BlitzIt.auth.formTZ(invoice.issuedOn, 'dd LLL yyyy')}`
    ]

    if (invoice.dueOn != null) {
        strList.push(`Due On: ${BlitzIt.auth.formTZ(invoice.dueOn, 'dd LLL yyyy')}`);
    }

    currentY += 15;
    doc.text(strList, rightM, currentY, { align: 'right', lineHeightFactor: 2.2 });

    //bill to
    doc.setFontSize(12);
    doc.setTextColor('black');
    currentY += 30;
    doc.text(['Bill To:', invoice.buyer.companyName], leftM, currentY, { align: 'left', lineHeightFactor: 1.5 });

    //line items
    doc.setTextColor(lightBlackColor);
    doc.setFontSize(11);

    var qtyM = 17;
    var descM = 38;
    var unitPriceM = 133;
    var totalM = 180;
    currentY += 20;

    doc.text('Qty', qtyM, currentY);
    doc.text('Description', descM, currentY);
    doc.text('Unit Price', unitPriceM, currentY);
    doc.text('Total', totalM, currentY, { align: 'right' });
    
    currentY += 2;
    doc.line(leftM, currentY, rightM, currentY);

    currentY += 6;

    invoice.lineItems.forEach(lineItem => {
        doc.text(toDisplayNumber(lineItem.quantity), qtyM, currentY);
        doc.text(lineItem.description, descM, currentY);
        doc.text(toCurrency(lineItem.unitPrice), unitPriceM, currentY);
        doc.text(toCurrency(lineItem.unitPrice * lineItem.quantity), totalM, currentY, { align: 'right' });
        currentY += 5;
    });

    doc.line(leftM, currentY, rightM, currentY);
    currentY += 5;
    doc.text('Subtotal:', unitPriceM, currentY);
    doc.text(toCurrency(invoice.subTotal), totalM, currentY, { align: 'right' });
    currentY += 5;
    doc.text('GST:', unitPriceM, currentY);
    doc.text(toCurrency(invoice.taxTotal), totalM, currentY, { align: 'right' });
    currentY += 2;
    doc.line(unitPriceM, currentY, rightM, currentY);
    currentY += 6;
    doc.setFontSize(14);
    doc.text('Total:', unitPriceM, currentY);
    doc.text(toCurrency(invoice.taxTotal + invoice.subTotal), totalM, currentY, { align: 'right' });

    window.open(doc.output('bloburl'), '_blank');
    //doc.save('invoice.pdf');
}
